/** HEADER **/

header {

  &.sticky {
    width: 100%;
    right: auto;
    left: auto;
    top: 0px;
    z-index: 10;
    transition: top .6s ease-in-out,
      background-color .5s ease-in, 
      padding-top .4s ease-in-out,
      padding-right .4s ease-in-out,
      padding-left .4s ease-in-out;
    
    nav li a {
      color: $white;
      &:hover { 
        text-decoration: none;
      }
    }
  }
  
  &.sticky-free {
    //padding: 3em;
    //position: absolute;
    position: fixed;

    @media only screen and (max-width : 990px) {
      padding: 0;
      position: fixed;
      top: 0px;
    }

    .top-bar {
      background-color: $primary-color;
      padding: 0 1em;
      //border-top: 8px solid $highlight;
      //border-right: 8px solid $primary-light;
      //border-bottom: 8px solid $primary-dark;
      //border-left: 8px solid $primary-light;
      border-bottom: 2px solid $primary-dark;

      @media only screen and (max-width : 990px) {
        background-color: $primary-color+e8;
        padding: 0rem 1em;
        border-top: 0;
        border-right: 0;
        border-bottom: 2px solid $primary-dark;
        border-left: 0;
        box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.35);
      }
    }
  }

  &.sticky-top {
    padding: 0;
    position: fixed;
    top: 0px;

    .top-bar {
      background-color: $primary-color+e8;
      padding: 0rem 1em;
      border-bottom: 2px solid $primary-dark;
      box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.35);
    }
  }

  &.sticky-up {
    position: fixed;
    top: -100px;
    opacity: .5;
    transition: all .5s ease-out;
    .top-bar {
      background-color: $white;
      padding: 0rem 1em;
      border-bottom: 1px solid $white;
    }
  }

  .hamburger-box {
    margin-top: 4px;
  }

  .hamburger-inner, 
  .hamburger-inner.is-active {
    background-color: $white !important;
    &:after, &:before {
      background-color: $white !important;
    }
  }

}

.mobile-navigation {
  transition: all .2s ease-in;
  display: none;

  &.is-active {
    display: inherit;
  }

  ul {
    flex-direction: column;
    padding-bottom: 1rem;
  }

  li {
    display: block;
    margin-left: 0;
    align-self: flex-end;
  }

}

nav {
  margin-right: 1rem;

  ul {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    display: inline-block;
    font-weight: 700;
    margin-left: 2.5rem;
    color: $white;
    font-size: 1.25rem;
    line-height: 1.7;
  }
  a {
    text-decoration: none;
    display: block;
    text-transform: uppercase;
    padding: 5px 0 2px;
    position: relative;

    &:hover{
      &:after {
        bottom: 2px;
        opacity: 1;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      opacity: 0;
      height: 3px;
      background: $highlight;
      transition: all .1s ease-in;
    }
  }
}