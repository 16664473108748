
/** Import everything from autoload */
//@import "./autoload/**/*";

/** Custom */
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers";

/** Fonts */
@import "common/fonts";

/** Import theme styles */
@import "common/variables";
@import "common/animations";
@import "common/global";
@import "layouts/header";
@import "layouts/footer";
@import "layouts/page";
@import "layouts/blog";
@import "layouts/contact";
@import "layouts/form";

/** Import Modules **/
@import "modules/modules";