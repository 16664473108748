/** MODULES **/

.module {
  overflow: hidden;
  position: relative;

  .module-title {
    color: $secondary-color;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 3rem;
    margin-top: 0;
    position: relative;
    display: inline-block;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      opacity: 1;
      height: 4px;
      background: $secondary-light;
      transition: all .1s ease-in;
    }
  }

  &.hero-module {
    background: $secondary-color;
    background: linear-gradient(110deg, $secondary-color 0%, $secondary-light 100%);
    background-size: cover;
    background-repeat: no-repeat;

    img {
      max-width: 100%;
    }

    .hero-arrow {
      position: absolute;
      left: 50%;
      bottom: 2rem;
      margin-left: -32px;
      z-index: 5;
    }

    .wave {
      width: 100%; 
      height: 100px;  
      border: solid 5px $white;
      border-color: $white transparent transparent transparent;
      border-radius: 50%/100px 100px 0 0;
      background-color: $white;
      position: absolute;
      bottom: 0;
    }

    .hero-content {
      position: relative;

      @media only screen and (max-width : 990px) {
        margin-top: 15vh;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
      }
      
      .hero-header {
        color: $white;
        font-weight: 700;
        font-family: 'Lovelo', 'Open Sans', sans-serif;
        text-transform: initial;

        @media only screen and (max-width : 480px) {
          line-height: 2rem;
        }

        .intro-text { 
          display: block;

          @media only screen and (max-width : 990px) {
            margin-left: 0;
            margin-bottom: 0;
          }
          @media only screen and (max-width : 480px) {
            font-size: 1.25rem;
          }
        }

        .header-text {
          font-size: 3rem;
          @media only screen and (max-width : 990px) {
            font-size: 3rem;
          }
          @media only screen and (max-width : 480px) {
            font-size: 2rem;
          }
        }
        
        &.add-background {
          margin-bottom: 1rem;

          .intro-text {
            margin-left: 1.5rem;
            margin-bottom: 0.75rem;
            z-index: 9;
            position: relative;

            @media only screen and (max-width : 990px) {
              margin-left: 0;
              margin-bottom: 0;
            }
            @media only screen and (max-width : 480px) {
              font-size: 1.25rem;
            }

            span {
              background-color: $grey;
              padding: 0 1rem;
            }
          }

          .header-text {
            background-color: $primary-color;
            color: $white;
            padding: 0.5rem 1rem;

            @media only screen and (max-width : 990px) {
              font-size: 3rem;
            }
            @media only screen and (max-width : 480px) {
              font-size: 2rem;
            }
          }
        }
      }

      .hero-text, .hero-text p {
        color: $white;
        font-size: 1.25rem;

        &.add-background p {
          background-color: $secondary-color;
          padding: 0 1rem;
          margin-top: 4px;
          display: none;
        }

        strong, b {
          text-transform: uppercase;
          font-size: 1.35rem;
          display: inline-block;
          padding: 0 4px;
          transform: translateY(2px);
          color: $highlight;
          font-weight: 700;
        }
      }

      .hero-button {
        border: none;
        border-radius: 4px;
        padding: 0.5em 2em;
        background-color: $black;
        color: $white;
        font-weight: 700;
        text-transform: uppercase;
      }
      .alt-color {
        color: $highlight;
        font-weight: 700;
      }

      .alt-font {
        font-size: 1.75rem;
        text-transform: initial;
      }

    }
  }

  &.content-module {

    .content-block {
      min-height: 80vh;
      padding: 4em 0;
    }

    img {
      max-width: 100%;
      width: 100%;

      @media (max-width: 992px) {
        margin: 2rem auto;
      }
    }

    p {
      .alt-color {
        font-weight: 700;
        text-transform: uppercase;
        color: $secondary-color;
        font-size: 1.5rem;
      }
    }
  }

  &.threecol-module {
    @media (max-width: 991px) {
      margin: 2rem 0;
    }

    .threecol-title {
      font-size: 1.625rem;
    }

    .columns-container:last-child {
      margin-bottom: 0rem;
    }

    .column-item-wrapper {
      @media (max-width: 991px) {
        margin-bottom: 2rem;
      }

      &:last-child {
        margin-bottom: 0rem;
      }
    }

    .column-item {
      background: $pure-white;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      padding: 2rem 1.5rem;
      box-sizing: border-box;
      border: 3px solid $off-white;

      img {
        width: 256px;
        height: auto;
        max-width: 100%;
        margin: 0 auto;
        margin-bottom: 1.5rem;
      }

      h3 {
        color: $secondary-color;
        text-transform: uppercase;
        font-size: 1.625rem;
        font-weight: 700;
      }

      .alt-color {
        font-weight: 700;
        color: $black;
      }

    }
  }

  &.parallax-module {
    
    .parallax-item {
      height: 100vh;
      background-position: center;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
    }
  }

  &.gallery-module {
    &.full-height {
      .gallery-image {
        height: 100vh;
      }
    }
    .carousel-item {
      position: relative;
      max-height: 100vh;
    }
    .carousel-control-next,
    .carousel-control-prev {
      //background-color: $highlight;
    }
    .gallery-image {
      display: block;
      height: 720px;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0;
    }
    video {
      position: relative;
      z-index: 1;
      top: 0;
      right: 0;
      bottom: 0;
      left:0;
      display: block;
    }
  }

  &.mosaic-module {
    .contact-box {
      color: $pure-white;
      background-color: $highlight;
      padding: 4rem;
      text-align: center;
      min-height: 25rem;
      justify-content: center;
      align-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-top: 0.5rem;

      .approval-icon {
        background-image: url("/images/branding/like.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 7rem;
        height: 7rem;
        display: block;
        margin: 2rem;
        margin-top: 0;
      }

      img {
        padding: 2rem;
        width: 12rem;
        padding-top: 0;
      }
    }

    .mosaic-item {
      float: left;
      padding: 0.5rem;
      margin: 0;
      overflow: hidden;
      transition: ease-in-out background-color 0.3s;

      &:hover {
        background-color: $secondary-light;
        .section-header:after{ transform: scaleX(1); }
      }

      a {
        position: relative;
        display: block;
        height: 100%;
        background-color: $black;

        img { transition: ease-in-out opacity 0.3s, ease-in-out transform 0.3s; }

        &:hover {
          img { 
            opacity: 0.4;
            transform: scale(1.1);
          }
          span { opacity: 1; }
        }
      }
      span {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        
        text-align: center;
        height: 100%;
        justify-content: center;
        flex-direction: column;

        transition: ease-in-out opacity 0.3s;

        opacity: 0;

        .section-header {
          &:after {
            content: '';
            max-width: 33%;
            margin: 0 auto;
            display: block;
            border-bottom: solid 3px $white;  
            transform: scaleX(0);  
            transition: transform .4s ease-in-out;
            border-color: $highlight;
          }
          @media (max-width: 991px) {
            font-size: 1.25rem;
          }
        }
        
        h3, p { 
          color: #fefefe;
          padding: 0 2rem;
        }
        p { 
          margin-bottom: 0;
          font-size: 1rem;
        }
      }
    }
  }

}