/** VARIABLES **/

$highlight: #f3d040;

$primary-dark: #903d62;
$primary-color: #bd515a;
$primary-light: #d69a4e;

$secondary-dark: #425bbd;
$secondary-color: #425bbd;
$secondary-light: #45a1de;


$black: #23213d;
$grey: #302f3e;
$white: #f2f2f0;
$off-white: #e3e3e3;
$pure-white: #fefefe;

$error: #dc3545;
$success: #28a745;

$transparent: hsla(0,0%,97%,0);
