/** ANIMATIONS **/

.floating {  
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.wave-block {
  display: block;
  width: 100%;
  overflow: hidden;
  background-color: #45a1de;
  padding: 3rem;
  clip-path: circle(14rem at center);
  margin: 2rem auto;

  @media only screen and (max-width : 1200px) {
    clip-path: circle(12rem at center);
  }
  @media only screen and (max-width : 440px) {
    clip-path: circle(9rem at center);
    margin: 1rem auto;
    padding: 0;
  }
}
.wave-element {
  //background-image: url("/images/branding/wave.png");
  mask-image: url("/images/branding/wave.png");
  background: linear-gradient(to bottom, #7cd8eb 0%, #45a1de 85%);
  background-repeat: repeat-x;
  background-position: center;
  background-size: contain;
  display: block;
  width: 400px;
  height: 70px;
  padding-right: 40rem;
  padding-left: 40rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.wave-left {
  animation: move-left 3s ease infinite;
}
.wave-right {
  animation: move-right 3s ease infinite;
}

.animated {
  &.delay {
    animation-delay: 2s;
  }
}

@keyframes floating {
  from { transform: translate(0,  0px); }
  65%  { transform: translate(0, 15px); }
  to   { transform: translate(0, -0px); }    
}

@keyframes move-left {
  from { transform: translate(-100px,  0px); }
  50% { transform: translate(0px, 0px); }
  to { transform: translate(-100px, 0px); }
}

@keyframes move-right {
  50% { transform: translate(-100px, 0px); }
}

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #555;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 1s linear infinite;
  display: none;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
