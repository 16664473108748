

.contact-is-active {

  #contact {
    z-index: 11;
    opacity: 1;
    right: 0;
    transition: opacity 0.4s ease-in-out 0s, right 0.5s ease-in-out;
  }

  #contact-bg {
    z-index: 10;
    opacity: 1;
    transform: translateX(0) skew(0deg,0deg);
    
    @media only screen and (max-width : 990px) {
      transform: translateX(0) skew(0deg,0deg);
    }
  }

}

#contact-bg {
  background: $black;
  opacity: 1;
  transform: translateX(150%) skew(15deg,0deg);
  transition: all .4s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 10;
}

#contact {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0s, right 0.4s ease-in-out;
  overflow-y: auto;
  overflow-x: hidden;
  color: $white;

  &.is-active {
    z-index: 11;
    opacity: 1;
    right: 0;
    background-color: $black;
  }

  #site-contact {
    @media only screen and (max-width : 768px) {
      margin-bottom: 3rem;
    }
  }

  .contact-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 1.5rem;

    img {
      max-width: 80px;
      margin-right: 2rem;
    }

    p {
      margin: 0;
      font-weight: 700;

      a {
        color: $white;
        font-size: 1.25rem;
      }
    }
  }

  .close-contact {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 1;
    z-index: 12;
    cursor: pointer;

    &:after {
      content: " ";
      transform: rotate(-45deg);
      position: absolute;
      left: 15px;
      height: 33px;
      width: 3px;
      background-color: $white;
      display: block;
    }

    &:before {
      content: " ";
      transform: rotate(45deg);
      position: absolute;
      left: 15px;
      height: 33px;
      width: 3px;
      background-color: $white;
      display: block;
    }
  }

  h2 {
    color: $primary-color;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 700;
    margin-bottom: 2rem;
    margin-top: 0;
    position: relative;
    display: inline-block;

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      opacity: 1;
      height: 4px;
      background: $highlight;
      transition: all .1s ease-in;
    }
  }

  .contact-form {
    color: $white;
    
    p {
      font-weight: 700;
    }

    p, li {
      color: $white;
      font-size: 1rem;
    }

  }
}
