/** HEADER **/

.wrapper {
  padding-top: 10rem;
  padding-bottom: 4em;
  overflow: hidden;
}

.cta {
  margin: 0 auto 3rem;
  padding: 3rem;
  padding-top: 0;

  .col {
    border-top: solid 3px $secondary-light;
  }

  h2 {
    margin-top: 3rem;
    color: $secondary-color;
  }

  p {
    margin: 1rem 0;
  }

  .button {
    margin: 0;
  }
}

.page-title {
  color: $secondary-color;
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 700;
  margin-bottom: 3rem;
  margin-top: 0;
  position: relative;
  display: inline-block;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: -8px;
    left: 0;
    width: 100%;
    opacity: 1;
    height: 4px;
    background: $secondary-light;
    transition: all .1s ease-in;
  }
}

.page, .modularPage, .work {
  .featured-image {
    max-width: 100%;
  }

  .block {
    margin-bottom: 3rem;
  }
}

.work {
  .image-container { margin: 0 auto; }
  .description {
    p {
      display: flex;
      flex-direction: column;
      font-family: 'Lovelo', 'Open Sans', serif;
      span {
        font-size: 1.5rem;
        color: $primary-color;
        margin-right: .7rem;
        display: block;
      }
    }
  }

  .text-container {
    padding-left: 5em;
    @media only screen and (max-width : 768px) {
      padding-left: inherit;
    }
  }

  .button { 
    color: $primary-color;
    &:hover { color: $white; }
  }
}

.modularPage {
  .title-container {
    margin-bottom: 0rem;
  }

  .image-container {
    width: 100%;
  }
  .featured-image {
    max-width: 100%;
  }

  .text-container {
    @media only screen and (max-width : 990px) {
      margin: 0 auto;
      margin-bottom: 2rem;
    }
  }
}
