/** GLOBALS **/

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: 'Lovelo', 'Open Sans', sans-serif;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  background-color: $white;
  color: $black;
  position: relative;
}

ul {
  list-style: none;
  li {
    line-height: 2;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    text-rendering: optimizeLegibility;
    font-weight: 300;
    color: $black;
    font-size: 1.25rem;
  }
}

p {
  line-height: 2;
  text-rendering: optimizeLegibility;
  font-weight: 300;
  color: $black;
  font-size: 1.25rem;
  margin-bottom: 1.25rem;

  strong, b {
    font-weight: 700;
    color: $black;
  }

  a {
    text-decoration: none;
    font-weight: 700;
    color: $primary-light;
    transition: all .1s ease-in;
  
    &:hover {
      text-decoration: none;
      color: $primary-color;
    }
  }
}

.text-container {
  strong, b {
    font-weight: 700;
    text-transform: uppercase;
    color: $secondary-color;
    font-size: 1.5rem;
  }
}

.layer-up {
  position: relative;
  z-index: 5;
}

.full-height {
  min-height: 100vh;
}

.logo-wrapper {
  text-decoration: none;
  .logo {
    margin: 0;
    padding: 0;
    color: $white;
    font-weight: 700;
    font-size: 2.75rem;
    font-family: 'Pacifico', cursive;
    width: 64px;
    padding: 8px;
    margin: 8px;
  }
}

.button {
  font-family: inherit;
  font-size: inherit;
  color: $black;
  background: none;

  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  outline: none;
  position: relative;
  
  text-decoration: none;

  border: 3px solid;
  margin-top: 1rem;
  padding: 0.4rem 1rem;

  cursor: pointer;
  transition: all .3s ease-out;

  &:hover {
    text-decoration: none;
    color: $white;
    background-color: $black;
    border-color: $black;
  }
}

.content-container {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

.section-header {
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 1.5;
  font-weight: 700;
  margin-top: 0;
}

@mixin clearfix() {
  &::after {
    display: block;
    content: "";
    clear: both;
  }
}