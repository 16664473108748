/** FOOTER **/

footer {
  padding: 2em 4em;
  background-color: $black;
  border-top: $primary-color 4px solid;

  p {
    font-size: 90%;
    margin-bottom: 0;
    color: $white;
  }

  a.text-link {
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    position: relative;
    color: $primary-color;
    font-weight: 700;

    &:hover{
      text-decoration: none;
      color: $primary-color;
      &:after {
        bottom: 2px;
        opacity: 1;
      }
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      opacity: 0;
      height: 3px;
      background: $primary-color;
      transition: all .1s ease-in;
    }
  }
}