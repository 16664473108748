.blog {

  article { margin-bottom: 4rem; }

  blockquote {
    font-weight: 700;
    font-size: 2rem;
    font-style: italic;
    margin-left: -3rem;
    padding: 1rem 0px 1rem 40px;
    border-left: 4px solid $primary-color;
  }

  .blog-header {
    h1 { 
      font-weight: 700;
      line-height: 1.3;
      font-size: 2.75rem;
    }
  }
}