.contact-form {
  .message {
    padding: 1rem;
    border: 3px solid $error;
    text-align: middle;
    margin-bottom: 2rem;
    text-align: center;
    margin-right: 30px;
    background-color: $grey;

    p, li { font-size: 1rem; }

    ul { 
      margin-bottom: 1.25rem;
      padding: 0 2rem;
    }

    &.error {
      border-color: $error;
      p, li { color: $error !important; }
    }

    &.notice {
      border-color: $success;
      color: $success !important;
    }

    &.ajax { display: none; }
  }
}

form input[type="email"], 
form input[type="text"], 
form textarea {
  width: 100%;
  border: 3px solid $highlight;
  font-family: Open Sans,sans-serif;
  padding: 1rem 1.2rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
  line-height: 1.5;
}

input#boofer { display: none; }

form input[type="submit"] {
  padding: 8px 14px;
  border: 3px solid $primary-color;
  color: $primary-color;
  transition: all .3s ease-in;
  margin-top: 1.4rem;

  &:hover {
    background: $primary-color;
    color: $white;
    border: 3px solid $primary-color;
  }
}

form label {
  display: block;
  font-size: 0.85rem;
  color: $white;
  margin-bottom: .625rem;
}