// Font Mixin
@mixin font-face($font-family, $url) {
	@font-face {
		font-family: '#{$font-family}';
		src: url('#{$url}.eot');
		src: url('#{$url}.eot?#iefix') format('embedded-opentype'),
		     url('#{$url}.woff') format('woff'),
		     url('#{$url}.ttf') format('truetype'),
         url('#{$url}.svg') format('svg'),
         url('#{$url}.otf') format('opentype');
		font-weight: normal;
		font-style: normal;
	}
}

/** Google Fonts */
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700');


/** File Fonts */
//@include font-face('Nexa', '/fonts/NexaDemo-Light');
@include font-face('Lovelo', '/fonts/Lovelo-Black');
